import type { PricingList } from "@warrenio/api-spec/spec.oats.gen";
import { produce } from "../../utils/immer.ts";
import { hostPool1FakeId, hostPool1PerformanceId } from "./hostPoolsExample.ts";
import { loc1Tll, loc4Trtu } from "./locationsExample.ts";

const _basePricingExample = {
    EUR: {
        DEFAULT: [
            {
                resource_type: "CPU",
                resource_prices: [
                    {
                        price_per_unit: 0.007224,
                        num_cpus_from: 3,
                        description: "Virtual Machine less than 3 CPUs",
                    },
                    {
                        price_per_unit: 0.00956,
                        num_cpus_from: 3,
                        description: "Virtual Machine 3+ CPUs",
                    },
                ],
            },
            {
                resource_type: "RAM",
                resource_prices: [
                    {
                        price_per_unit: 0.00845,
                        gigabytes_from: 0,
                        description: "Virtual Machine GiB RAM",
                    },
                ],
            },
            {
                resource_type: "BLOCK_STORAGE",
                resource_prices: [
                    {
                        price_per_unit: 0.000234,
                        gigabytes_from: 1,
                        description: "Virtual Machine disk 1-80 GiB",
                    },
                    {
                        price_per_unit: 0.00065,
                        gigabytes_from: 81,
                        description: "Virtual Machine disk 81+ GiB",
                    },
                ],
            },
            {
                resource_type: "BLOCK_STORAGE_BACKUP",
                resource_prices: [
                    {
                        gigabytes_from: 1,
                        price_per_unit: 0.0002,
                        description: "Backup of disk 1-80 GiB",
                    },
                    {
                        gigabytes_from: 81,
                        price_per_unit: 0.0003,
                        description: "Backup of disk 81+ GiB",
                    },
                ],
            },
            {
                resource_type: "BLOCK_STORAGE_SNAPSHOT",
                resource_prices: [
                    {
                        gigabytes_from: 1,
                        price_per_unit: 0.0004,
                        description: "Snapshot of disk 1-80 GiB",
                    },
                    {
                        gigabytes_from: 81,
                        price_per_unit: 0.0005,
                        description: "Snapshot of disk 81+ GiB",
                    },
                ],
            },
            {
                resource_type: "WINDOWS_LICENSE",
                resource_prices: [
                    {
                        price_per_unit: 0.00748,
                        description: "Windows license (per CPU)",
                    },
                ],
            },
            {
                resource_type: "OBJECT_STORAGE",
                resource_prices: [
                    {
                        price_per_unit: 0.00814,
                        description: "Object storage",
                    },
                ],
            },
            {
                resource_type: "UNASSIGNED_FLOATING_IP",
                resource_prices: [
                    {
                        price_per_unit: 0.000112,
                        description: "Unassigned floating IP",
                    },
                ],
            },
            {
                resource_type: "ASSIGNED_FLOATING_IP",
                resource_prices: [],
            },
            {
                resource_type: "LOAD_BALANCER",
                resource_prices: [
                    {
                        price_per_unit: 0.003,
                        description: "Load balancer",
                    },
                ],
            },
        ],
        [loc1Tll]: [
            {
                resource_type: "CPU",
                resource_prices: [
                    {
                        num_cpus_from: 1,
                        price_per_unit: 0.00821,
                        description: "Virtual Machine 1-2 CPUs",
                    },
                    {
                        num_cpus_from: 3,
                        price_per_unit: 0.00976,
                        description: "Virtual Machine 3+ CPUs",
                    },
                ],
            },
            {
                resource_type: "RAM",
                resource_prices: [
                    {
                        gigabytes_from: 0.5,
                        price_per_unit: 0.00475,
                        description: "Virtual Machine 0.5-2 GiB RAM",
                    },
                    {
                        gigabytes_from: 3,
                        price_per_unit: 0.01197,
                        description: "Virtual Machine 3+ GiB RAM",
                    },
                ],
            },
            {
                resource_type: "BLOCK_STORAGE",
                resource_prices: [
                    {
                        gigabytes_from: 1,
                        price_per_unit: 0.00016,
                        description: "Virtual Machine disk 1-80 GiB",
                    },
                    {
                        gigabytes_from: 81,
                        price_per_unit: 0.00019,
                        description: "Virtual Machine disk 81+ GiB",
                    },
                ],
            },
            {
                resource_type: "BLOCK_STORAGE_BACKUP",
                resource_prices: [
                    {
                        gigabytes_from: 1,
                        price_per_unit: 0.00026,
                        description: "Backup of disk 1-80 GiB",
                    },
                    {
                        gigabytes_from: 81,
                        price_per_unit: 0.00029,
                        description: "Backup of disk 81+ GiB",
                    },
                ],
            },
            {
                resource_type: "BLOCK_STORAGE_SNAPSHOT",
                resource_prices: [
                    {
                        gigabytes_from: 1,
                        price_per_unit: 0.00036,
                        description: "Snapshot of disk 1-80 GiB",
                    },
                    {
                        gigabytes_from: 81,
                        price_per_unit: 0.00039,
                        description: "Snapshot of disk 81+ GiB",
                    },
                ],
            },
            {
                resource_type: "WINDOWS_LICENSE",
                resource_prices: [
                    {
                        price_per_unit: 0.00543,
                        description: "Windows license (per CPU)",
                    },
                ],
            },
            {
                resource_type: "OBJECT_STORAGE",
                resource_prices: [
                    {
                        price_per_unit: 0.00632,
                        description: "Object storage",
                    },
                ],
            },
            {
                resource_type: "UNASSIGNED_FLOATING_IP",
                resource_prices: [
                    {
                        price_per_unit: 0.00534,
                        description: "Unassigned floating IP",
                    },
                ],
            },
            {
                resource_type: "ASSIGNED_FLOATING_IP",
                resource_prices: [
                    {
                        price_per_unit: 0.003,
                        description: "Assigned floating IP",
                    },
                ],
            },
            {
                resource_type: "LOAD_BALANCER",
                resource_prices: [
                    {
                        price_per_unit: 0.005,
                        description: "Load balancer",
                    },
                ],
            },
        ],
        [loc4Trtu]: [
            {
                resource_type: "CPU",
                resource_prices: [
                    {
                        num_cpus_from: 1,
                        price_per_unit: 0.00821,
                        description: "Virtual Machine 1-2 CPUs",
                    },
                    {
                        num_cpus_from: 3,
                        price_per_unit: 0.00976,
                        description: "Virtual Machine 3+ CPUs",
                    },
                ],
            },
            {
                resource_type: "RAM",
                resource_prices: [
                    {
                        gigabytes_from: 0.5,
                        price_per_unit: 0.00475,
                        description: "Virtual Machine 0.5-2 GiB RAM",
                    },
                    {
                        gigabytes_from: 3,
                        price_per_unit: 0.01197,
                        description: "Virtual Machine 3+ GiB RAM",
                    },
                ],
            },
            {
                resource_type: "BLOCK_STORAGE",
                resource_prices: [
                    {
                        gigabytes_from: 1,
                        price_per_unit: 0.00016,
                        description: "Virtual Machine disk 1-80 GiB",
                    },
                    {
                        gigabytes_from: 81,
                        price_per_unit: 0.00019,
                        description: "Virtual Machine disk 81+ GiB",
                    },
                ],
            },
            {
                resource_type: "BLOCK_STORAGE_BACKUP",
                resource_prices: [
                    {
                        gigabytes_from: 1,
                        price_per_unit: 0.00026,
                        description: "Backup of disk 1-80 GiB",
                    },
                    {
                        gigabytes_from: 81,
                        price_per_unit: 0.00029,
                        description: "Backup of disk 81+ GiB",
                    },
                ],
            },
            {
                resource_type: "BLOCK_STORAGE_SNAPSHOT",
                resource_prices: [
                    {
                        gigabytes_from: 1,
                        price_per_unit: 0.00036,
                        description: "Snapshot of disk 1-80 GiB",
                    },
                    {
                        gigabytes_from: 81,
                        price_per_unit: 0.00039,
                        description: "Snapshot of disk 81+ GiB",
                    },
                ],
            },
            {
                resource_type: "WINDOWS_LICENSE",
                resource_prices: [
                    {
                        price_per_unit: 0.00543,
                        description: "Windows license (per CPU)",
                    },
                ],
            },
            {
                resource_type: "OBJECT_STORAGE",
                resource_prices: [
                    {
                        price_per_unit: 0.00632,
                        description: "Object storage",
                    },
                ],
            },
            {
                resource_type: "UNASSIGNED_FLOATING_IP",
                resource_prices: [],
            },
            {
                resource_type: "ASSIGNED_FLOATING_IP",
                resource_prices: [],
            },
            {
                resource_type: "LOAD_BALANCER",
                resource_prices: [
                    {
                        price_per_unit: 0.005,
                        description: "Load balancer",
                    },
                ],
            },
        ],
        [`host_pool:${hostPool1FakeId}`]: [
            {
                resource_type: "CPU",
                resource_prices: [
                    {
                        price_per_unit: 0.00721,
                        num_cpus_from: 0,
                        description: "Virtual Machine less than 3 CPUs",
                    },
                    {
                        price_per_unit: 0.00956,
                        num_cpus_from: 3,
                        description: "Virtual Machine 3+ CPUs",
                    },
                ],
            },
            {
                resource_type: "RAM",
                resource_prices: [
                    {
                        price_per_unit: 0.00845,
                        gigabytes_from: 0,
                        description: "Virtual Machine GiB RAM",
                    },
                ],
            },
            {
                resource_type: "BLOCK_STORAGE",
                resource_prices: [
                    {
                        price_per_unit: 0.0002,
                        gigabytes_from: 1,
                        description: "Virtual Machine disk 1-80 GiB",
                    },
                    {
                        price_per_unit: 0.0005,
                        gigabytes_from: 81,
                        description: "Virtual Machine disk 81+ GiB",
                    },
                ],
            },
            {
                resource_type: "BLOCK_STORAGE_BACKUP",
                resource_prices: [
                    {
                        gigabytes_from: 1,
                        price_per_unit: 0.0006,
                        description: "Backup of disk 1-80 GiB",
                    },
                    {
                        gigabytes_from: 81,
                        price_per_unit: 0.0007,
                        description: "Backup of disk 81+ GiB",
                    },
                ],
            },
            {
                resource_type: "BLOCK_STORAGE_SNAPSHOT",
                resource_prices: [
                    {
                        gigabytes_from: 1,
                        price_per_unit: 0.0008,
                        description: "Snapshot of disk 1-80 GiB",
                    },
                    {
                        gigabytes_from: 81,
                        price_per_unit: 0.0009,
                        description: "Snapshot of disk 81+ GiB",
                    },
                ],
            },
            {
                resource_type: "WINDOWS_LICENSE",
                resource_prices: [
                    {
                        price_per_unit: 0,
                        description: "Windows license (per CPU)",
                    },
                ],
            },
            {
                resource_type: "OBJECT_STORAGE",
                resource_prices: [
                    {
                        price_per_unit: 0.00814,
                        description: "Object storage",
                    },
                ],
            },
            {
                resource_type: "UNASSIGNED_FLOATING_IP",
                resource_prices: [
                    {
                        price_per_unit: 0.00234,
                        description: "Unassigned floating IP",
                    },
                ],
            },
            {
                resource_type: "ASSIGNED_FLOATING_IP",
                resource_prices: [],
            },
            {
                resource_type: "LOAD_BALANCER",
                resource_prices: [
                    {
                        price_per_unit: 5.003,
                        description: "Load balancer",
                    },
                ],
            },
        ],
        [`host_pool:${hostPool1PerformanceId}`]: [
            {
                resource_type: "CPU",
                resource_prices: [
                    {
                        price_per_unit: 0.00721,
                        num_cpus_from: 0,
                        description: "Virtual Machine less than 3 CPUs",
                    },
                    {
                        price_per_unit: 0.00956,
                        num_cpus_from: 3,
                        description: "Virtual Machine 3+ CPUs",
                    },
                ],
            },
            {
                resource_type: "RAM",
                resource_prices: [
                    {
                        price_per_unit: 0.00845,
                        gigabytes_from: 0,
                        description: "Virtual Machine GiB RAM",
                    },
                ],
            },
            {
                resource_type: "BLOCK_STORAGE",
                resource_prices: [
                    {
                        price_per_unit: 0.004,
                        gigabytes_from: 1,
                        description: "Virtual Machine disk 1-80 GiB",
                    },
                    {
                        price_per_unit: 0.01,
                        gigabytes_from: 81,
                        description: "Virtual Machine disk 81+ GiB",
                    },
                ],
            },
            {
                resource_type: "BLOCK_STORAGE_BACKUP",
                resource_prices: [
                    {
                        gigabytes_from: 1,
                        price_per_unit: 0.005,
                        description: "Backup of disk 1-80 GiB",
                    },
                    {
                        gigabytes_from: 81,
                        price_per_unit: 0.006,
                        description: "Backup of disk 81+ GiB",
                    },
                ],
            },
            {
                resource_type: "BLOCK_STORAGE_SNAPSHOT",
                resource_prices: [
                    {
                        gigabytes_from: 1,
                        price_per_unit: 0.007,
                        description: "Snapshot of disk 1-80 GiB",
                    },
                    {
                        gigabytes_from: 81,
                        price_per_unit: 0.008,
                        description: "Snapshot of disk 81+ GiB",
                    },
                ],
            },
            {
                resource_type: "WINDOWS_LICENSE",
                resource_prices: [
                    {
                        price_per_unit: 0.0003,
                        description: "Windows license (per CPU)",
                    },
                ],
            },
            {
                resource_type: "OBJECT_STORAGE",
                resource_prices: [
                    {
                        price_per_unit: 0.00814,
                        description: "Object storage",
                    },
                ],
            },
            {
                resource_type: "UNASSIGNED_FLOATING_IP",
                resource_prices: [
                    {
                        price_per_unit: 0.00234,
                        description: "Unassigned floating IP",
                    },
                ],
            },
            {
                resource_type: "ASSIGNED_FLOATING_IP",
                resource_prices: [],
            },
            {
                resource_type: "LOAD_BALANCER",
                resource_prices: [
                    {
                        price_per_unit: 0.003,
                        description: "Load balancer",
                    },
                ],
            },
        ],
    },
} as const satisfies PricingList;

const _freePricingExample = {
    EUR: {
        DEFAULT: [
            {
                resource_type: "CPU",
                resource_prices: [
                    {
                        price_per_unit: 0,
                        num_cpus_from: 0,
                        description: "Virtual Machine less than 3 CPUs",
                    },
                ],
            },
            {
                resource_type: "RAM",
                resource_prices: [
                    {
                        price_per_unit: 0.0,
                        gigabytes_from: 0,
                        description: "Virtual Machine GiB RAM",
                    },
                ],
            },
            {
                resource_type: "BLOCK_STORAGE",
                resource_prices: [
                    {
                        price_per_unit: 0,
                        gigabytes_from: 1,
                        description: "Virtual Machine disk 1-80 GiB",
                    },
                ],
            },
            {
                resource_type: "BLOCK_STORAGE_BACKUP",
                resource_prices: [
                    {
                        gigabytes_from: 1,
                        price_per_unit: 0,
                        description: "Backup of disk 1-80 GiB",
                    },
                ],
            },
            {
                resource_type: "BLOCK_STORAGE_SNAPSHOT",
                resource_prices: [
                    {
                        gigabytes_from: 1,
                        price_per_unit: 0,
                        description: "Snapshot of disk 1-80 GiB",
                    },
                ],
            },
            {
                resource_type: "WINDOWS_LICENSE",
                resource_prices: [
                    {
                        price_per_unit: 0,
                        description: "Windows license (per CPU)",
                    },
                ],
            },
            {
                resource_type: "OBJECT_STORAGE",
                resource_prices: [
                    {
                        price_per_unit: 0,
                        description: "Object storage",
                    },
                ],
            },
            {
                resource_type: "UNASSIGNED_FLOATING_IP",
                resource_prices: [
                    {
                        price_per_unit: 0,
                        description: "Unassigned floating IP",
                    },
                ],
            },
            {
                resource_type: "ASSIGNED_FLOATING_IP",
                resource_prices: [],
            },
            {
                resource_type: "LOAD_BALANCER",
                resource_prices: [
                    {
                        price_per_unit: 0,
                        description: "Load balancer",
                    },
                ],
            },
        ],
    },
} as const satisfies PricingList;

const _missingPricingExample = {
    EUR: {
        DEFAULT: [],
    },
} as const satisfies PricingList;

const _largeNumbersRoundingPricingExample = {
    IDR: {
        DEFAULT: [
            {
                resource_type: "CPU",
                resource_prices: [
                    {
                        price_per_unit: 145.18,
                        num_cpus_from: 0,
                        description: "Virtual Machine less than 3 CPUs",
                    },
                ],
            },
            {
                resource_type: "RAM",
                resource_prices: [
                    {
                        price_per_unit: 84.01,
                        gigabytes_from: 0,
                        description: "Virtual Machine GiB RAM",
                    },
                ],
            },
            {
                resource_type: "BLOCK_STORAGE",
                resource_prices: [
                    {
                        price_per_unit: 2.83,
                        gigabytes_from: 1,
                        description: "Virtual Machine disk 1-80 GiB",
                    },
                ],
            },
            {
                resource_type: "BLOCK_STORAGE_BACKUP",
                resource_prices: [
                    {
                        gigabytes_from: 1,
                        price_per_unit: 5.13,
                        description: "Backup of disk 1-80 GiB",
                    },
                ],
            },
            {
                resource_type: "BLOCK_STORAGE_SNAPSHOT",
                resource_prices: [
                    {
                        gigabytes_from: 1,
                        price_per_unit: 6.9,
                        description: "Snapshot of disk 1-80 GiB",
                    },
                ],
            },
            {
                resource_type: "WINDOWS_LICENSE",
                resource_prices: [
                    {
                        price_per_unit: 96.01,
                        description: "Windows license (per CPU)",
                    },
                ],
            },
            {
                resource_type: "OBJECT_STORAGE",
                resource_prices: [
                    {
                        price_per_unit: 111.73,
                        description: "Object storage",
                    },
                ],
            },
            {
                resource_type: "UNASSIGNED_FLOATING_IP",
                resource_prices: [
                    {
                        price_per_unit: 104.4,
                        description: "Unassigned floating IP",
                    },
                ],
            },
            {
                resource_type: "UNASSIGNED_FLOATING_IP",
                resource_prices: [
                    {
                        price_per_unit: 94.4,
                        description: "Unassigned floating IP",
                    },
                ],
            },
            {
                resource_type: "LOAD_BALANCER",
                resource_prices: [
                    {
                        price_per_unit: 53.03,
                        description: "Load balancer",
                    },
                ],
            },
        ],
    },
} as const satisfies PricingList;

type ExampleLocations = keyof (typeof _basePricingExample)[keyof typeof _basePricingExample];

export const basePricingExample = addDescriptionSuffix(_basePricingExample, {
    DEFAULT: "DEF",
    [loc1Tll]: "tll",
    [loc4Trtu]: "trtu",
    [`host_pool:${hostPool1FakeId}`]: "Fake",
    [`host_pool:${hostPool1PerformanceId}`]: "Fast",
} satisfies Record<ExampleLocations, unknown>);

function addDescriptionSuffix(pricingList: PricingList, suffixes: Record<string, string | undefined>): PricingList {
    return produce(pricingList, (draft) => {
        for (const currencyItem of Object.values(draft)) {
            for (const [location, locationPrices] of Object.entries(currencyItem)) {
                for (const priceItem of locationPrices) {
                    for (const price of priceItem.resource_prices) {
                        const suffix = suffixes[location];
                        if (price.description && suffix) {
                            price.description += ` (@${suffix})`;
                        }
                    }
                }
            }
        }
    });
}

type LocationPricingList = PricingList[string];

function multiplyPrices(locationPrices: LocationPricingList, multiplier: number, round = false): LocationPricingList {
    return produce(locationPrices, (draft) => {
        for (const item of Object.values(draft)) {
            for (const resourceType of item) {
                for (const price of resourceType.resource_prices) {
                    const newPrice = price.price_per_unit * multiplier;
                    price.price_per_unit = round ? Math.round(newPrice * 100) / 100 : newPrice;
                }
            }
        }
    });
}

function makeMultiCurrencyExample(basePricingExample: PricingList): PricingList {
    return {
        ...basePricingExample,
        USD: multiplyPrices(basePricingExample.EUR, 2),
        JPY: multiplyPrices(basePricingExample.EUR, 130, true),
        IDR: multiplyPrices(basePricingExample.EUR, 17000 / 6, true),
        ZAR: multiplyPrices(basePricingExample.EUR, 20),
        THB: multiplyPrices(basePricingExample.EUR, 40),
        TND: multiplyPrices(basePricingExample.EUR, 3),
    };
}

export const pricingExample = makeMultiCurrencyExample(basePricingExample);
export const freePricingExample = makeMultiCurrencyExample(_freePricingExample);
export const missingPricingExample = makeMultiCurrencyExample(_missingPricingExample);

export const largeNumbersRoundingPricingExample = {
    ..._largeNumbersRoundingPricingExample,
};
