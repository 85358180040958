import type { SyncStorage } from "jotai/vanilla/utils/atomWithStorage";
import invariant from "tiny-invariant";
import { jsonLocalStorage } from "../utils/jotai/atomStorage.ts";
import { event_mockDbHotReload } from "./db.events.ts";
import type { MockDb } from "./defaultDb.ts";
import * as _defaultDb from "./defaultDb.ts";
import type { MockDbOptions } from "./mockDbVariants.ts";
import type { MockDbInitOptions } from "./msw/mswOptions.ts";

// Mutable alias for hot reload
let defaultDb = _defaultDb;

interface MockDbState {
    initialized: boolean;
    mockDb: MockDb | undefined;
    dirtySaveTimer: ReturnType<typeof setTimeout> | undefined;
    /** Whether to store the mock DB in localStorage or reset it on every reload. */
    persistent: boolean;
}

const state: MockDbState = {
    initialized: false,
    mockDb: undefined,
    dirtySaveTimer: undefined,
    persistent: false,
};

export function initMockDb({ mockDbOptions, persistent }: MockDbInitOptions) {
    state.initialized = true;
    state.persistent = persistent;

    if (!state.persistent) {
        console.debug("Setting up new temporary mock DB: %o", mockDbOptions);
        state.mockDb = defaultDb.buildMockDb(mockDbOptions);
    }
}

export function resetDbWithOptions(options: MockDbOptions) {
    console.debug("Resetting mockDb with options", options);
    state.mockDb = defaultDb.buildMockDb(options);
}

/** NB: Call this function after any mutations to {@link mockDb}. */
export function markDirty() {
    if (state.dirtySaveTimer) {
        return;
    }

    state.mockDb!.modified = true;
    state.dirtySaveTimer = setTimeout(() => {
        saveDb();
        state.dirtySaveTimer = undefined;
    }, 100);
}

export function getMockDb(): MockDb {
    if (!state.initialized) {
        throw new Error("Mock DB not initialized");
    }

    if (!state.mockDb) {
        loadDb();
    }
    return state.mockDb!;
}

function loadFromData(data: MockDb | undefined) {
    const options = data?.options;
    if (data && data.VERSION !== defaultDb.VERSION) {
        console.warn("Resetting outdated mockDb from storage", data);
        data = undefined;
    } else {
        console.debug("Updated mockDb from storage", data);
    }

    const baseDb = defaultDb.buildMockDb(options);
    const newData =
        // If data has been not been modified, reload the defaults to ensure we have the latest version of them.
        data?.modified === false ? baseDb : { ...baseDb, ...data };

    state.mockDb = newData;
}

//#region Storage

const mockDbKey = "mockDb";
const storage = jsonLocalStorage() as SyncStorage<MockDb | undefined>;

export function reloadDb() {
    console.debug("Reload mockDb");
    state.mockDb = undefined;
    loadDb();
}

function loadDb() {
    invariant(state.persistent, "Cannot load mockDb in non-persistent mode");

    storage.subscribe!(mockDbKey, loadFromData, undefined);
    loadFromData(storage.getItem(mockDbKey, undefined));
}

function saveDb() {
    if (!state.persistent) {
        console.error("Cannot save mockDb in non-persistent mode");
        return;
    }

    console.debug("Saving mockDb to localStorage");
    storage.setItem(mockDbKey, getMockDb());
}

//#endregion

//#region Hot reload

import.meta.hot?.accept(["./defaultDb.ts"], ([newDefaultDb]: unknown[]) => {
    defaultDb = newDefaultDb as typeof _defaultDb;

    if (state.mockDb && state.persistent) {
        console.debug("Hot reload defaultDb to %o", newDefaultDb);
        reloadDb();
    }

    // Notify front-end to eg. clear query caches
    document.dispatchEvent(new Event(event_mockDbHotReload));
});

//#endregion
